
import { login, sendCaptcha, loginUser } from "@/api/index.js";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { isWeiXin } from "@/utils/bank";
import Toastrs from "@/utils/Toastrs";
import sondCode from '@/views/components/sondCode'
@Component
export default class MoSms extends Vue {
  public form: any = {
    mobile: "",
    verification: "",
    purpose: "login",
  };
  public dunwNum: number = 60;

  public async sondCode() {
    sondCode({
      appid: (this as any).appid,
      type: "sms",
      mobile: this.form.mobile,
      number: this.dunwNum,
      setNum: (number: any) => {
        this.dunwNum = number;
      },
      setCode: (code: any) => {
        this.form.verification = code;
      },
    });
  }

  public async login() {
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(this.form.mobile)) {
      Toastrs.error(`请输入正确的手机号码`);
      return;
    }

    if (this.form.verification.length != 4) {
      Toastrs.error(`请输入正确的验证码`);
      return;
    }

    let obj = this.getUrlParams((this as any).login_url);
    delete obj.appid;
    delete obj.redirect_uri;
    delete obj.user_type;
    delete obj.channels;

    const res: any = await ((this as any).user_type == 2 ? loginUser : login)({
      source_appid: (this as any).appid,
      channels: "sms",
      ...obj,
      ...this.form,
    });
    if (res.code == 200 || res.code ==0) {
      (this as any).loginSuccess(res.data.token);
    }
  }

  getUrlParams(urlStr: string) {
    var url = "?" + urlStr.split("?")[1];
    var theRequest: any = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      let strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }
}
